import qs from 'qs'
import request from '@/utils/request'

export function getLoginLogList (params) {
  return request.get('/login-logs', { params })
}

export function exportLoginLog (params) {
  return request.get('/login-logs/export', {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function getOperationLogList (params) {
  return request.get('/operation-logs', { params })
}

export function exportOperationLog (params) {
  return request.get('/operation-logs/export', {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}

export function getNginxAccessLogList (params) {
  return request.get('/middleware-nginx-access-logs', { params })
}

export function getNginxAccessLogTrend (params) {
  return request.get('/middleware-nginx-access-logs/trend', { params })
}
